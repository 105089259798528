<template>
  <div class="form-container">
    <div
      v-if="!areInstructionsSent">
      <div
        class="d-flex flex-column"
      >
        <span class="send-link-text mt-14 mx-5">{{ $t("common.weSendLink") }}</span>
        <v-form
          class="py-15 mx-5"
          lazy-validation
          ref="forgotPwd"
          @submit="onSubmit()"
          @submit.prevent
        >
          <label for="email" class="custom-input-label">{{ $t("common.emailAddress") }}</label>
          <v-text-field
            class="mt-2"
            id="email"
            v-model="email"
            :rules="[$rules.required, $rules.email]"
            outlined
            solo
            flat
            required
          />
          <v-btn
            elevation="0"
            class="btn-icon shadow-white my-2"
            block
            rounded
            x-large
            color="btn-grad--orange"
            @click.stop="onSubmit"
          >
            <span class="text-center w-100">{{ $t("buttons.send") }}</span>
            <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
          </v-btn>
        </v-form>
      </div>
    </div>
    <div v-else class="d-flex flex-column mx-5 py-15 my-10">
      <div class="shadow-smoke bg-white py-15 py-md-5 text-center">
        <span>{{ $t('typography.weSendLinkForgot') }}</span>
      </div>
      <v-btn
        elevation="0"
        class="btn-icon shadow-white mt-10"
        block
        color="btn-grad--orange"
        rounded
        x-large
        to="/login"
      >
        <span class="text-center w-100">{{ $t("buttons.loginSecond") }}</span>
        <v-icon color="var(--color__white)">mdi-chevron-right-circle-outline</v-icon>
      </v-btn>
    </div>
  </div>
</template>

<script>

export default {
  name: 'ForgotPassMain',
  data () {
    return {
      email: '',
      areInstructionsSent: false
    }
  },
  methods: {
    async onSubmit () {
      if (this.$refs.forgotPwd.validate()) {
        await this.$doLoading(async () => {
          await this.$showGqlError(async () => {
            await this.$store.dispatch('startPasswordReset', {
              apollo: this.$apollo,
              email: this.email
            })
            this.areInstructionsSent = true
          })
        })
      }
    }
  }
}
</script>

<style scoped lang="scss">
span.send-link-text {
  font-size: var(--font-size__title--szm);
  color: var(--color__dark);
  font-weight: var(--font-weight__medium);
  text-align: left;
}
</style>
